import { CModal, CModalHeader, CSpinner, CModalBody, CButton, CModalFooter } from "@coreui/react";
import React, { useState } from "react";

const LiscenceKeyModel = ({ message, onConfirm, onCancel, isOpen, LiscenceKey, updateMessage, loadingForLiscence }) => {
    const [copied, setCopied] = useState(false);

    const handleCopyToClipboard = () => {
        const licenseKey = LiscenceKey;
        const textArea = document.createElement('textarea');
        textArea.value = licenseKey;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setCopied(true);
    };

    const handleCloseModal = () => {
        setCopied(false);
        onCancel();
    };

    return (
        <CModal alignment="center" visible={isOpen} onClose={handleCloseModal} color="danger">
            {loadingForLiscence ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px' }}>
                    <CSpinner />
                </div>
            ) : (
                <>
                    <CModalHeader onClose={handleCloseModal}>
                        <h5>{LiscenceKey}</h5>
                    </CModalHeader>
                    <CModalBody>
                        <h6>{message ? message : updateMessage}</h6>
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color="primary"
                            style={{ marginLeft:'10px'}}
                            onClick={handleCopyToClipboard}
                        >
                            {copied ? 'Copied!' : 'Copy License Key'}
                        </CButton>
                        {" "}
                        {message !== 'License Key created successfully.' && (
                            <CButton color="success" onClick={() => onConfirm()}>
                                Update
                            </CButton>
                        )}
                    </CModalFooter>
                </>
            )}
        </CModal>
    );
}

export default LiscenceKeyModel;
