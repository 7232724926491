import {
    CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CCardTitle, CFormInput, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead,
    CTableHeaderCell, CTableRow, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem,
} from "@coreui/react";
import React from 'react'
import { getComapanyResponseDetailsConfig, getCompanyMachines, getCompanyCrops, addCompanyResponseConfig, getCompanyResponceConfigurationsApi, CopyMasterResponseConfig } from "../../../../utils/api";
import { useEffect } from "react";
import { useState } from "react";
import { MdDeleteForever } from 'react-icons/md';
import AppMessageModal from "../../../../components/AppMessageModal";
import DropdownWithSelectedOptions from "../../Crops/components/dropDown"

function CompanyDefaultResponseConfigurations({ crop_name }) {
    const [allMachines, setAllMachines] = useState([]);
    const [allCrops, setAllCrops] = useState([]);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [selectedCrop, setSelectedCrop] = useState(null);
    const classType = ['DEFAULT_UPJAO', 'DEFAULT_ENAM']
    const [selectClassType, setSelectClassType] = useState(null)
    const [masterSelectedMachine, setMasterSelectedMachine] = useState(null);
    const [masterSelectedCrop, setMasterSelectedCrop] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pipelineID, setPipelineID] = useState('')
    const [getInitialResponceDefaultClassConfig, setInitialResponceDefaultClassConfig] = useState(false)
    const [addConfig, setAddconfig] = useState(false)
    const [messageModal, setMessageModal] = useState(false);
    const [messagetitle, setMessageTitle] = useState('');
    const [message, setMessage] = useState('');
    const [allClasses, setAllClasses] = useState([])
    const processImage = ["classes", "bboxes", "encodings", "encoding_colors", "bboxes_bottom"]
    const kernelDetails = ["classes", "total_kernel_count", "total_kernel_weight", "consider_area",
        "consider_length", "consider_length_dist", "kernel_counts", "kernel_weights",
        "weight_thresholds", "quality_score_dependencies", "overall_score", "areas", "kernel_lengths", "length_distributions", "range_distributions"]
    const kernelDistributions = ["kernel_dist"]
    const distAvgIndexes = ["kernel_avg_dist"]
    const alerts = ["alerts"]
    const croppedKernelDetails = ["classes", "bboxes", "beautify", "bboxes_bottom"]
    const segmentImage = ["segment_image"]
    const [selectedOptionsForProcessImage, setselectedOptionsForProcessImage] = useState([]);
    const [selectedOptionsForKernelDetails, setselectedOptionsForKernelDetails] = useState([]);
    const [selectedOptionsForKernelDistributions, setselectedOptionsForKernelDistributions] = useState([]);
    const [selectedOptionsForDistAvgIndexes, setselectedOptionsForDistAvgIndexes] = useState([]);
    const [selectedOptionsForAlerts, setselectedOptionsForAlerts] = useState([]);
    const [selectedOptionsForCroppedKernelDetails, setselectedOptionsForCroppedKernelDetails] = useState([]);

    const allValues = {
        selectedCrop,
        selectedMachine,
        pipelineID,
        selectedOptionsForProcessImage,
        selectedOptionsForKernelDetails,
        selectedOptionsForKernelDistributions,
        selectedOptionsForDistAvgIndexes,
        selectedOptionsForAlerts,
        selectedOptionsForCroppedKernelDetails
    };
    const setAllValues = (values) => {
        setSelectedCrop(values.selectedCrop || null);
        setSelectedMachine(values.selectedMachine || null);
        setPipelineID(values.pipelineID || '');
        setselectedOptionsForProcessImage(values.selectedOptionsForProcessImage || []);
        setselectedOptionsForKernelDetails(values.selectedOptionsForKernelDetails || []);
        setselectedOptionsForKernelDistributions(values.selectedOptionsForKernelDistributions || []);
        setselectedOptionsForDistAvgIndexes(values.selectedOptionsForDistAvgIndexes || []);
        setselectedOptionsForAlerts(values.selectedOptionsForAlerts || []);
        setselectedOptionsForCroppedKernelDetails(values.selectedOptionsForCroppedKernelDetails || []);
    };

    const handleSelectOptionForProcessImage = (option) => {
        setselectedOptionsForProcessImage([...selectedOptionsForProcessImage, option]);
    };
    const handleRemoveOptionForProcessImage = (optionToRemove) => {
        const updatedOptions = selectedOptionsForProcessImage.filter((option) => option !== optionToRemove);
        setselectedOptionsForProcessImage(updatedOptions);
    };

    const handleSelectOptionForKernelDetails = (option) => {
        setselectedOptionsForKernelDetails([...selectedOptionsForKernelDetails, option]);
    };
    const handleRemoveOptionForKernelDetails = (optionToRemove) => {
        const updatedOptions = selectedOptionsForKernelDetails.filter((option) => option !== optionToRemove);
        setselectedOptionsForKernelDetails(updatedOptions);
    };

    const handleSelectOptionForKernelDistributions = (option) => {
        setselectedOptionsForKernelDistributions([...selectedOptionsForKernelDistributions, option]);
    };
    const handleRemoveOptionForKernelDistributions = (optionToRemove) => {
        const updatedOptions = selectedOptionsForKernelDistributions.filter((option) => option !== optionToRemove);
        setselectedOptionsForKernelDistributions(updatedOptions);
    };
    const handleSelectOptionForDistAvgIndexes = (option) => {
        setselectedOptionsForDistAvgIndexes([...selectedOptionsForDistAvgIndexes, option]);
    };
    const handleRemoveOptionForDistAvgIndexes = (optionToRemove) => {
        const updatedOptions = selectedOptionsForDistAvgIndexes.filter((option) => option !== optionToRemove);
        setselectedOptionsForDistAvgIndexes(updatedOptions);
    };
    const handleSelectOptionForAlerts = (option) => {
        setselectedOptionsForAlerts([...selectedOptionsForAlerts, option]);
    };
    const handleRemoveOptionForAlerts = (optionToRemove) => {
        const updatedOptions = selectedOptionsForAlerts.filter((option) => option !== optionToRemove);
        setselectedOptionsForAlerts(updatedOptions);
    };
    const handleSelectOptionForCroppedKernelDetails = (option) => {
        setselectedOptionsForCroppedKernelDetails([...selectedOptionsForCroppedKernelDetails, option]);
    };
    const handleRemoveOptionForCroppedKernelDetails = (optionToRemove) => {
        const updatedOptions = selectedOptionsForCroppedKernelDetails.filter((option) => option !== optionToRemove);
        setselectedOptionsForCroppedKernelDetails(updatedOptions);
    };
    useEffect(() => {
        getMachines();
        getCrops();
        getDefaultResponseGenConfigurations()
    }, []);
    const handleCopyMasterResponseConfig = async () => {
        const payload = {
            crop_id: masterSelectedCrop.id,
            machine_code: masterSelectedMachine.machine_code,
            type: selectClassType,
            company_id: window.location.href.split("/").pop()
        };
        const response = await CopyMasterResponseConfig(payload);
        if (response?.status === 200) {
            setMessageTitle('success');
            setMessage(response?.data?.message);
            setMessageModal(true);
            getDefaultResponseGenConfigurations()
        }
        if (response?.status === 400) {
            setMessageTitle('Error');
            setMessage(response?.data?.message);
            setMessageModal(true);
        }
    };
    const handleAddConfig = async () => {
        if (selectedMachine === '' || selectedCrop === '') {
            setMessage('Please select Required Fields');
            setMessageTitle('Error');
            setMessageModal(true);
            return;
        }
        try {
            const requestBody = {
                company_id: window.location.href.split("/").pop(),
                processed_image: selectedOptionsForProcessImage,
                kernel_details: selectedOptionsForKernelDetails,
                kernel_distributions: selectedOptionsForKernelDistributions,
                dist_avg_indexes: selectedOptionsForDistAvgIndexes,
                alerts: selectedOptionsForAlerts,
                cropped_kernel_details: selectedOptionsForCroppedKernelDetails,
                crop_id: selectedCrop.id,
                machine_code: selectedMachine.machine_code,
                pipeline_id: pipelineID,
            };
            const response = await addCompanyResponseConfig(requestBody);
            if (response?.status === 200) {
                setMessageTitle('Success');
                setMessage(response.data.message);
                setMessageModal(true);
                getDefaultResponseGenConfigurations()
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    async function getMachines() {
        try {
            let company_id = window.location.href.split("/").pop();
            const result = await getCompanyMachines(company_id)
            setAllMachines(result?.data?.data?.machines_data);
        } catch (error) {
            console.log(error);
        }
    }
    async function getCrops() {
        try {
            let company_id = window.location.href.split("/").pop();
            const result = await getCompanyCrops(company_id)
            setAllCrops(result?.data?.data?.crops);
        } catch (error) {
            console.log(error);
        }
    }
    const getDefaultResponseGenConfigurations = async () => {
        try {
            setLoading(true)
            const company_id = window.location.href.split("/").pop()
            const response = await getCompanyResponceConfigurationsApi(company_id);
            if (response?.data?.data) {
                setAllClasses(response?.data?.data?.companyResponseConfig)
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    const copyValuesToClipboard = () => {
        navigator.clipboard.writeText(JSON.stringify(allValues)).then(() => {
            console.log('Values copied to clipboard');
        }).catch((error) => {
            console.error('Error copying values to clipboard:', error);
        });
    };

    const pasteValuesFromClipboard = () => {
        navigator.clipboard.readText().then((text) => {
            try {
                const values = JSON.parse(text);
                setAllValues(values);
            } catch (error) {
                console.error('Failed to parse clipboard content:', error);
            }
        }).catch((error) => {
            console.error('Failed to read clipboard content:', error);
        });
    };

    const handleViewButtonClick = async (crop_id, machine_code) => {
        setLoading(true)
        const payload = {
            crop_id: crop_id,
            machine_code: machine_code,
            company_id: window.location.href.split("/").pop(),
        };
        const response = await getComapanyResponseDetailsConfig(payload);
        if (response?.data?.data) {
            const selectedCrop = allCrops && allCrops.find(crop => crop.id === crop_id);
            const selectedMachine = allMachines && allMachines.find(machine => machine.machine_code === machine_code);
            setSelectedCrop(selectedCrop);
            setSelectedMachine(selectedMachine);
            setPipelineID(response?.data?.data?.pipeline_id)
            setselectedOptionsForProcessImage(response?.data?.data?.processed_image)
            setselectedOptionsForKernelDetails(response?.data?.data?.kernel_details)
            setselectedOptionsForKernelDistributions(response?.data?.data?.kernel_distributions)
            setselectedOptionsForDistAvgIndexes(response?.data?.data?.dist_avg_indexes)
            setselectedOptionsForAlerts(response?.data?.data?.alerts)
            setselectedOptionsForCroppedKernelDetails(response?.data?.data?.cropped_kernel_details)
            setInitialResponceDefaultClassConfig(true);
            setLoading(false)
            setAddconfig(false)
        }
    };
    const handleDeleteButtonClick = (index) => {
        // Logic for handling the "Delete" button click for the item at the specified index
    };
    const handleAddButtonClick = () => {
        setSelectedMachine('')
        setSelectedCrop('')
        setPipelineID('')
        setselectedOptionsForProcessImage([])
        setselectedOptionsForKernelDetails([])
        setselectedOptionsForKernelDistributions([])
        setselectedOptionsForDistAvgIndexes([])
        setselectedOptionsForAlerts([])
        setselectedOptionsForCroppedKernelDetails([])
        setInitialResponceDefaultClassConfig(true);
        setAddconfig(true)
    }

    return (
        <>
            <div>
                <CCard className="mt-4 mb-5">
                    <CCardHeader>
                        <CRow className="justify-items-between ">
                            <CCol>
                                <CCardTitle>
                                    <h3>
                                        <strong>Copy Master Response Configurations</strong>
                                    </h3>
                                </CCardTitle>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol xs="2">
                                <CDropdown>
                                    <CDropdownToggle color="secondary">
                                        {selectClassType ? `${selectClassType}` : "Select Class Type"}
                                    </CDropdownToggle>
                                    <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                    <CDropdownMenu>
                                        {classType &&
                                            classType.map((type, index) => (
                                                <CDropdownItem
                                                    key={index}
                                                    onClick={() => setSelectClassType(type)}
                                                >
                                                    {type}
                                                </CDropdownItem>
                                            ))}
                                    </CDropdownMenu>
                                </CDropdown>
                            </CCol>
                            <CCol xs="2">
                                <CDropdown>
                                    <CDropdownToggle color="secondary">
                                        {masterSelectedCrop
                                            ? `${masterSelectedCrop.crop} - ${masterSelectedCrop.variety}`
                                            : "Select Crop"}
                                    </CDropdownToggle>
                                    <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                    <CDropdownMenu>
                                        {allCrops &&
                                            allCrops.map((crop, index) => (
                                                <CDropdownItem
                                                    key={index}
                                                    onClick={() => setMasterSelectedCrop(crop)}
                                                >
                                                    {crop.crop} - {crop.variety}
                                                </CDropdownItem>
                                            ))}
                                    </CDropdownMenu>
                                </CDropdown>
                            </CCol>
                            <CCol xs="2">
                                <CDropdown>
                                    <CDropdownToggle color="secondary">
                                        {masterSelectedMachine
                                            ? `${masterSelectedMachine.machine_name} - ${masterSelectedMachine.machine_code}`
                                            : "Select Machine"}
                                    </CDropdownToggle>
                                    <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                    <CDropdownMenu>
                                        {allMachines &&
                                            allMachines.map((machine, index) => (
                                                <CDropdownItem
                                                    key={index}
                                                    onClick={() => setMasterSelectedMachine(machine)}
                                                >
                                                    {machine.machine_name} - {machine.machine_code}
                                                </CDropdownItem>
                                            ))}
                                    </CDropdownMenu>
                                </CDropdown>
                            </CCol>
                            <CCol xs="2" className="align-items-center justify-content-end button-cell">
                                <CButton color="success" onClick={() => handleCopyMasterResponseConfig()}>COPY</CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </div>
            <div>
                <AppMessageModal message={message} title={messagetitle} handleClose={() => setMessageModal(false)} isOpen={messageModal} />
                <CCard className="mt-2 mb-5">
                    <CCardHeader>
                        <CRow className="justify-items-between ">
                            <CCol>
                                <CCardTitle>
                                    <h3>
                                        <strong>Default Response Configurations</strong>
                                    </h3>
                                </CCardTitle>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <CTable style={{ marginTop: '30px', marginBottom: '50px' }} align="middle">
                            <CTableHead style={{ background: "aliceblue" }}>
                                <CTableRow>
                                    <CTableHeaderCell>S.No</CTableHeaderCell>
                                    <CTableHeaderCell>ResponeID</CTableHeaderCell>
                                    <CTableHeaderCell>Crop</CTableHeaderCell>
                                    <CTableHeaderCell>Machine</CTableHeaderCell>
                                    <CTableHeaderCell>Action</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            {allClasses !== undefined ? (
                                <CTableBody>
                                    {allClasses && allClasses.map((item, index) => {
                                        return (
                                            <CTableRow key={index}>
                                                <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                                                <CTableDataCell>{item.reference_id}</CTableDataCell>
                                                <CTableDataCell>{item.crop} - {item.variety}</CTableDataCell>
                                                <CTableDataCell>{item.machine_code}</CTableDataCell>
                                                <CTableDataCell className="button-cell">
                                                    <CButton onClick={() => handleViewButtonClick(item.crop_id, item.machine_code)}>VIEW</CButton>
                                                    <CButton
                                                        color="danger"
                                                        style={{ marginLeft: '15px' }}
                                                        onClick={() => {
                                                            handleDeleteButtonClick(index)
                                                        }}
                                                    >
                                                        <MdDeleteForever size={22} />
                                                    </CButton>
                                                </CTableDataCell>
                                            </CTableRow>
                                        );
                                    })}
                                </CTableBody>
                            ) : (
                                <CTableHeaderCell>No Data to display</CTableHeaderCell>
                            )}
                        </CTable>
                        <CRow className="align-items-center">
                            <CCol xs="2">
                                <CDropdown>
                                    <CDropdownToggle color="secondary">
                                        {selectedCrop
                                            ? `${selectedCrop.crop} - ${selectedCrop.variety}`
                                            : "Select Crop"}
                                    </CDropdownToggle>
                                    <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                    <CDropdownMenu>
                                        {allCrops &&
                                            allCrops.map((crop, index) => (
                                                <CDropdownItem
                                                    key={index}
                                                    onClick={() => setSelectedCrop(crop)}
                                                >
                                                    {crop.crop} - {crop.variety}
                                                </CDropdownItem>
                                            ))}
                                    </CDropdownMenu>
                                </CDropdown>
                            </CCol>
                            <CCol xs="2">
                                <CDropdown>
                                    <CDropdownToggle color="secondary">
                                        {selectedMachine
                                            ? `${selectedMachine.machine_name} - ${selectedMachine.machine_code}`
                                            : "Select Machine"}
                                    </CDropdownToggle>
                                    <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                    <CDropdownMenu>
                                        {allMachines &&
                                            allMachines.map((machine, index) => (
                                                <CDropdownItem
                                                    key={index}
                                                    onClick={() => setSelectedMachine(machine)}
                                                >
                                                    {machine.machine_name} - {machine.machine_code}
                                                </CDropdownItem>
                                            ))}
                                    </CDropdownMenu>
                                </CDropdown>
                            </CCol>
                            <CCol xs="8" className="align-items-center justify-content-end button-cell">
                                <CButton color="success" onClick={() => handleAddButtonClick()}>+ ADD</CButton>
                                <CCol xs="2">
                                    <CDropdown>
                                        <CDropdownToggle color="primary">
                                            COPY
                                        </CDropdownToggle>
                                        <CDropdownMenu>
                                            {allClasses &&
                                                allClasses.map((item, index) => (
                                                    <CDropdownItem
                                                        key={index}
                                                        onClick={() => handleViewButtonClick(item.crop_id, item.machine_code)}
                                                    >
                                                        {item.crop}-{item.machine_code} - ({item.reference_id})
                                                    </CDropdownItem>
                                                ))}
                                        </CDropdownMenu>
                                    </CDropdown>
                                </CCol>
                                {getInitialResponceDefaultClassConfig && (
                                    <>
                                        <CButton color="primary" onClick={copyValuesToClipboard}>
                                            Copy Values
                                        </CButton>
                                        <CButton color="primary" onClick={pasteValuesFromClipboard}>
                                            Paste Values
                                        </CButton>
                                    </>)}
                            </CCol>
                        </CRow>
                        {
                            loading ?
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                                    <CSpinner color="dark" />
                                </div>
                                :
                                <>
                                    {getInitialResponceDefaultClassConfig &&
                                        <>
                                            <CRow className="mt-4">
                                                <CCol xs="auto" className="d-flex align-items-center">
                                                    <strong style={{ marginRight: "10px" }}>Pipeline ID</strong>
                                                    <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                                </CCol>
                                                <CCol xs="auto">
                                                    <CFormInput
                                                        type="text"
                                                        value={pipelineID}
                                                        onChange={(e) => setPipelineID(e.target.value)}
                                                        placeholder="Enter Pipeline ID"
                                                    />
                                                </CCol>
                                            </CRow>
                                            <div>
                                                <div style={{ marginTop: '50px' }}>
                                                    <strong style={{ fontSize: '20px' }}>Processed Image : </strong>
                                                    <div style={{ marginTop: '10px' }}>
                                                        <div>
                                                            <DropdownWithSelectedOptions options={processImage.map((option) => option)} onSelect={handleSelectOptionForProcessImage} title={"Select an Option"} onRemove={handleRemoveOptionForProcessImage} selectedOptions={selectedOptionsForProcessImage} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mt-4"></hr>
                                                <div style={{ marginTop: '30px' }}>
                                                    <strong style={{ fontSize: '20px' }}>Kernel Details : </strong>
                                                    <div style={{ marginTop: '10px' }}>
                                                        <div>
                                                            <DropdownWithSelectedOptions options={kernelDetails.map((option) => option)} onSelect={handleSelectOptionForKernelDetails} title={"Select an Option"} onRemove={handleRemoveOptionForKernelDetails} selectedOptions={selectedOptionsForKernelDetails} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mt-4"></hr>
                                                <div style={{ marginTop: '30px' }}>
                                                    <strong style={{ fontSize: '20px' }}>Kernel Distributions : </strong>
                                                    <div style={{ marginTop: '10px' }}>
                                                        <div>
                                                            <DropdownWithSelectedOptions options={kernelDistributions.map((option) => option)} onSelect={handleSelectOptionForKernelDistributions} title={"Select an Option"} onRemove={handleRemoveOptionForKernelDistributions} selectedOptions={selectedOptionsForKernelDistributions} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mt-4"></hr>
                                                <div style={{ marginTop: '30px' }}>
                                                    <strong style={{ fontSize: '20px' }}>Dist Avg Indexes : </strong>
                                                    <div style={{ marginTop: '10px' }}>
                                                        <div>
                                                            <DropdownWithSelectedOptions options={distAvgIndexes.map((option) => option)} onSelect={handleSelectOptionForDistAvgIndexes} title={"Select an Option"} onRemove={handleRemoveOptionForDistAvgIndexes} selectedOptions={selectedOptionsForDistAvgIndexes} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mt-4"></hr>
                                                <div style={{ marginTop: '30px' }}>
                                                    <strong style={{ fontSize: '20px' }}>Alerts : </strong>
                                                    <div style={{ marginTop: '10px' }}>
                                                        <div>
                                                            <DropdownWithSelectedOptions options={alerts.map((option) => option)} onSelect={handleSelectOptionForAlerts} title={"Select an Option"} onRemove={handleRemoveOptionForAlerts} selectedOptions={selectedOptionsForAlerts} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mt-4"></hr>
                                                <div style={{ marginTop: '30px' }}>
                                                    <strong style={{ fontSize: '20px' }}>Cropped Kernel Details : </strong>
                                                    <div style={{ marginTop: '10px' }}>
                                                        <div>
                                                            <DropdownWithSelectedOptions options={croppedKernelDetails.map((option) => option)} onSelect={handleSelectOptionForCroppedKernelDetails} title={"Select an Option"} onRemove={handleRemoveOptionForCroppedKernelDetails} selectedOptions={selectedOptionsForCroppedKernelDetails} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {addConfig ?
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                                    <button onClick={handleAddConfig} className="btn btn-primary">Submit</button>
                                                </div>
                                                :
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                                    <button onClick={handleAddConfig} className="btn btn-primary">Update</button>
                                                </div>}
                                        </>
                                    }
                                </>
                        }
                    </CCardBody>
                </CCard>
            </div>
        </>
    )
}

export default CompanyDefaultResponseConfigurations